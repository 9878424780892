import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'

export namespace DotNetSpatialServiceAPI {

  export class DetermineFileCoordinateSystems implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.POST;
    parse = (data: AxiosResponse) => data.data

    constructor(public params: object = {}) {
      this.path = '/netcorespatialservice/srs/determine'
    }
  }

  export class ConvertFromDxfFile implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.POST;
    parse = (data: AxiosResponse) => data.data
    hasFiles = true

    constructor (public params: object = {}, public hideNotification: boolean = false) {
      this.path = '/netcorespatialservice/convert/'
    }
  }

  export class ConvertFromExistingFile implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.POST;
    parse = (data: AxiosResponse) => data.data

    constructor (public params: object = {}, public hideNotification: boolean = false) {
      this.path = '/netcorespatialservice/convert/storage_file'
    }
  }

  export class ConvertToGeoTiff implements APIRequest<string> {
    response: string;
    path = '/netcorespatialservice/raster/base64/convert';
    method = HTTPMethod.POST;
    parse = (data: AxiosResponse) => data.data
    blob = true
    fileName = 'screen.tiff'

    constructor (public params: object = {}) {
    }
  }

  export class LoadExportMetaData implements APIRequest<string> {
    response: string;
    path = '/netcorespatialservice/export/metadata';
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data

    constructor () {
    }
  }

  export class Export implements APIRequest<string> {
    response: string;
    path = '/netcorespatialservice/export';
    method = HTTPMethod.POST;
    parse = (data: AxiosResponse) => data.data;
    blob = true;
    fileName = '';

    constructor (public params: object = {}, public exportFilename: string) {
      this.fileName = exportFilename
    }
  }
}
